  export const dataProcess = [
    {  
       itemDesc: 'En este proyecto se realizaron diseños bajo la licencia oficial de Fútbol Club Barcelona para Hadi Internacional S.A. Siguiendo las guias de estilo brindadas por el Club se crearon elementos e ilustraciones de jugadores y otros componentes representativos, como el estadio Camp Nou y frases como "Més que un Club".',
    },
 ]

 export const dataQuote = [
   {  
      Text: 'No es secreto que el mundo real dode el diseñador se desempeña, no es el mundo del arte, pero si el mundo de comprar y vender.',
      Autor: 'Paul Rand',
   },

 ]
 